
import { Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, Paper, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow,
  Stack,
  Typography,
  Menu,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,  
  Tab,
  Tabs,
  Backdrop,
  CircularProgress} from "@mui/material";
import * as React from "react";
import { useDropzone } from 'react-dropzone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material/styles';
import * as AstroUtil from '../astro/AstroUtil';
import * as Utils from '../util/Utils'

import './MosaicDialog.css';
import MosaicGraph from "../graphs/MosaicGraph";

const SERVER_ROOT_URL = process.env.REACT_APP_SERVER_ROOT_URL;

const INTEGRATION_PERIOD = 10; // seconds
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function MosaicDialog(
  props: {
    open: boolean,
    target: any,
    utcDate: Date,
    mosaic: any,
    handleUpdateMosaic: (mosaic: any) => void,
    handleClose: () => void,
  }
) {
  const [frequency, setFrequency] = React.useState('1.4');
  const [duration, setDuration] = React.useState('30');
  const [width, setWidth] = React.useState('1');
  const [name, setName] = React.useState('lmc');
  const [tabValue, setTabValue] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mosaicSettings, setMosaicSettings] = React.useState<any>({});

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const { getRootProps, getInputProps } = useDropzone
  ({maxFiles: 1, 
    multiple: false,
    accept: { 'text/plain': ['.txt']},
    onDrop: acceptedFiles => {
      setMosaicSettings({});
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e && e.target) {
          const text = e.target.result as string;
          const lines = (text||'').split('\n');

          const settings: any = {
            'pointings': [],
            'primary_beam_fwhm': 0,
            'source': 'Generated by miriad mosgen',
            'width': 1,
            'frequency': 1.4,
            'mosaic_center': ''
          };

          let reference_ra = '';
          let reference_dec = '';
          for (const line of lines) {
            if (line.trim().startsWith('#')) {
              processMiriadProperty(line, settings);

              // get reference ra/dec
              if (line.toLowerCase().includes('reference position')) {
                const fields = line.trim().split(/\s+/);
                const radecField = fields.length > 0 ? fields[fields.length - 1] : '';
                const radec = radecField.split(',');
                reference_ra = radec.length > 1 ? radec[0] : '';
                reference_dec = radec.length > 1 ? radec[1] : '';
              }
            } else {
              let pointings = settings['pointings'] || [];
              const pointing = line.trim().split(/\s+/);
              if (pointing.length === 4) {
                // convert offset to actual ra/dec
                settings['duration'] = Number(pointing[2]) * INTEGRATION_PERIOD;
                const point_obj = {
                  ra: Number(pointing[0]) + AstroUtil.hms(reference_ra),
                  dec: Number(pointing[1]) + AstroUtil.dms(reference_dec),
                  duration: Number(pointing[2]) * INTEGRATION_PERIOD,
                  name: pointing[3].trim().replace('$', '')
                }
                pointings.push(point_obj);
                settings['pointings'] = pointings;
              }
            }
          }
          const total_duration = (settings['pointings']||[]).length * (settings['duration']||INTEGRATION_PERIOD)
          settings['total_duration_s'] = total_duration;
          setMosaicSettings(settings);
        }
      }
      reader.readAsText(acceptedFiles[0]);
    }
  });

  const processMiriadProperty = (line: string, settings: any) => {
    if (line.includes('Primary Beam FWHM taken to be:')) {
      const fields = line.split(/\s+/);
      if (fields.length > 2) {
        // second last field
        settings['primary_beam_fwhm'] = fields[fields.length - 2];
      }
    }

    if (line.includes('width=')) {
      const fields = line.split('width=');
      if (fields.length > 0) {
        // last field
        settings['width'] = fields[fields.length - 1];
      }
    }

    if (line.includes('frequency=')) {
      const fields = line.split('frequency=');
      if (fields.length > 0) {
        // last field
        settings['frequency'] = fields[fields.length - 1];
      }
    }

    if (line.includes('radec=')) {
      const fields = line.split('radec=');
      if (fields.length > 0) {
        // last field
        settings['mosaic_center'] = fields[fields.length - 1];
      }
    }
  }

  const handleChangeTabValue = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  React.useEffect(() => {
    if (props.open) {
      setMosaicSettings(props.mosaic);
    }    
  }, [props.open]);

  const mosgen = () => {
    setOpenBackdrop(true);

    const radec = Utils.getRaDec(props.target, props.utcDate);
    const data = {
      ra: radec['ra'],
      dec: radec['dec'],
      width: Number(width),
      frequency: Number(frequency),
      name: name,
      duration: duration
    };

    fetch(`${SERVER_ROOT_URL}/mosgen`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({'content-type': 'application/json'}),
    })
      .then((response: {json: () => any}) => response.json())
      .then((response: any) => {
        if (response.status === 'success') {
          const mosaic: any = response['mosaic'] || {};
          setMosaicSettings(mosaic);
        } else {
          console.log('mosgen error: ' + response['message']);
        }
      })
      .catch((err: Error) => {
        console.log('mosgen error: ' + err.toString());
      })
      .finally( () => {
        setOpenBackdrop(false);
      });

    setAnchorEl(null);
  }

  return (
    <Dialog open={props.open} PaperProps={{ sx: {height: '80%'} }}
      maxWidth="md" fullWidth>

      <Backdrop
        sx={
          (theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })
        }
        open={openBackdrop}
        onClick={e => setOpenBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


      <DialogTitle id="mosaic-dialog-title">
        Mosaic setup for {(props.target||{})['name']||''}
      </DialogTitle>

      <DialogContent>
        <Stack spacing={2} height={'100%'}>
          <Stack spacing={2} direction={'row'}>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
                <Stack spacing={0}>
                  <Typography variant="button"
                    width={'100%'} textAlign='center'>
                    Upload Mosaic File
                  </Typography>
                  <Typography className='hide' variant="caption" textAlign='center'>
                    Drag 'n' drop ONE file here, or click to select a file
                  </Typography>
                </Stack>
            </div>

            <Button color="primary" sx={{flexGrow: 2}}
              aria-label="setting" variant="outlined"
              onClick={(event) => {setAnchorEl(event.currentTarget)}}
            >
            Generate Mosaic
            </Button>

            <Menu
              slotProps={{ paper: { sx: { width: '250px', padding: '20px'  } } }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: -45,
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={e => setAnchorEl(null)}
            >
              <Stack spacing={2}>
                <TextField id="frequency" label="Frequency (GHz)" variant="standard" 
                  fullWidth
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}/>
                <TextField id="width" label="Width (deg)" variant="standard" 
                  fullWidth
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}/>
                <TextField id="duration" label="Duration (seconds)" variant="standard" 
                  fullWidth
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}/>
                <TextField id="name" label="Name" variant="standard" 
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}/>

                <Button fullWidth variant="contained"
                  onClick={mosgen}>Generate</Button>
              </Stack>

            </Menu>
          </Stack>

          <Tabs value={tabValue} onChange={handleChangeTabValue} >
            <Tab label="Text"/>
            <Tab label="Graph"/>
          </Tabs>

          <Paper hidden={tabValue !== 0} sx={{flexGrow: 4}}>
            <Stack spacing={2}>

            <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color={'primary'} />}
                  aria-controls="info-content"
                  id="info-header"
                >
                  Information
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="primary"
                    variant="h6" key={'source'} gutterBottom>
                    {mosaicSettings['source']}
                  </Typography>

                  <Stack direction={'row'} key={'primary_beam_fwhm'}>
                    <Typography variant="subtitle1" width={'30%'}>
                      Primary Beam FWHM (arcsec):
                    </Typography>
                    <Typography variant="subtitle1">
                      {Number(mosaicSettings['primary_beam_fwhm']).toFixed(2)}
                    </Typography>
                  </Stack>

                  <Stack direction={'row'} key={'width'}>
                    <Typography variant="subtitle1" width={'30%'}>
                      Width (deg):
                    </Typography>
                    <Typography variant="subtitle1">
                      {mosaicSettings['width']}
                    </Typography>
                  </Stack>

                  <Stack direction={'row'} key={'width'}>
                    <Typography variant="subtitle1" width={'30%'}>
                      Number of Pointings:
                    </Typography>
                    <Typography variant="subtitle1">
                      {(mosaicSettings['pointings']||[]).length}
                    </Typography>
                  </Stack>

                  <Stack direction={'row'} key={'width'}>
                    <Typography variant="subtitle1" width={'30%'}>
                      Total Duration (seconds):
                    </Typography>
                    <Typography variant="subtitle1">
                      {mosaicSettings['total_duration_s']}
                    </Typography>
                  </Stack>

                  <Stack direction={'row'} key={'frequency'}>
                    <Typography variant="subtitle1" width={'30%'}>
                    Frequency (GHz):
                    </Typography>
                    <Typography variant="subtitle1">
                      {mosaicSettings['frequency']}
                    </Typography>
                  </Stack>

                  <Stack direction={'row'} key={'mosaic_center'}>
                    <Typography variant="subtitle1" width={'30%'}>
                    Center (RA/Dec):
                    </Typography>
                    <Typography variant="subtitle1">
                      {mosaicSettings['mosaic_center']}
                    </Typography>
                  </Stack>

                </AccordionDetails>
              </Accordion>

              <TableContainer component={Paper} sx={{ width: '100%'}}>
                <Table aria-label="simple table">
                  <TableHead sx={{ backgroundColor: '#cfd8dc'}}>
                    <TableRow>
                      <TableCell key={'head-1'} width={'30'} align="center">RA</TableCell>
                      <TableCell key={'head-2'} width={'30%'} align="center">Dec</TableCell>
                      <TableCell key={'head-3'} width={'10%'} align="center">Duration (seconds)</TableCell>
                      <TableCell key={'head-4'} width={'30%'} align="center">Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {((mosaicSettings||{})['pointings']||[]).map((pointing: any) => (
                      <StyledTableRow key={pointing['name']}>
                        <TableCell align="center">
                          {pointing['ra']}
                        </TableCell>
                        <TableCell align="center">
                          {pointing['dec']}
                        </TableCell>
                        <TableCell align="center">
                          {pointing['duration']}
                        </TableCell>
                        <TableCell align="center">
                        {pointing['name']}
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Paper>

          <Paper hidden={tabValue !== 1} sx={{flexGrow: 4}}>
            <MosaicGraph refresh={tabValue} mosaicSettings={mosaicSettings}/>
          </Paper>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={e => props.handleUpdateMosaic(mosaicSettings)}>
          Save
        </Button>

        <Button onClick={props.handleClose}>
          Close
        </Button>        
      </DialogActions>
    </Dialog>
  );
};
